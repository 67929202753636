import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import { useRouter } from 'next-translate-routes/router';
import { type FC, useEffect, useState } from 'react';

import { Icon } from '@/components';
import { fetchFilteredBlogItems } from '@/services/supabase';
import type { IBlogItemDTO } from '@/types/blogInterfaces';
import { cn } from '@/utils';

interface IRelatedArticlesProps {
  country: string;
  slug: string;
  publishedAt?: string;
  readTime?: number;
  className?: string;
}

export const RelatedArticles: FC<IRelatedArticlesProps> = ({
  className,
  country,
  publishedAt,
  readTime,
  slug,
}) => {
  const router = useRouter();
  const date = new Date(publishedAt as string);
  const { t } = useTranslation();

  const [blogItems, setBlogItems] = useState<IBlogItemDTO[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await fetchFilteredBlogItems({
        filter: {
          slug,
          field: 'Country',
          operator: '$eq',
          value: country,
        },
        pagination: {
          start: 0,
          limit: 3,
        },
      });
      if (error) {
        throw new Error(error.message);
      }
      setBlogItems(
        data.filter(
          (item: IBlogItemDTO) => item.attributes.locale === router.locale,
        ),
      );
    };
    fetchData();
  }, [country, slug]);

  if (!blogItems.length) {
    return null;
  }

  return (
    <div
      className={cn(
        'relative before:absolute before:inset-0 before:z-[-1] before:bg-blue-50 lg:before:rounded-2xl xl:before:inset-x-4',
        className,
      )}
    >
      <div className="relative px-5 pb-[96px] pt-16 md:px-[34px] lg:mx-auto lg:flex lg:w-full lg:max-w-[849px] lg:flex-col lg:px-0">
        <h4 className="text-[20px] font-semibold leading-6 text-blue-900 md:text-[24px] md:leading-7 md:tracking-[0.2px]">
          {t('blog.related_article')}
        </h4>
        <div className="mt-6 flex flex-col gap-4">
          {blogItems.map((item) => (
            <div
              key={item.id}
              className="shadow-close flex min-h-[130px] items-center gap-2 rounded-xl bg-white px-4 pb-4 pt-5 md:min-h-[106px] md:px-6 md:pb-[12px] md:pt-[28px] lg:gap-3"
            >
              <div className="flex flex-col justify-center gap-6">
                <Link href={`/blog/${item.attributes.slug}`}>
                  <p className="text-[15px] font-bold leading-5 tracking-[0.2px] text-blue-900 md:text-[20px] md:font-semibold md:leading-6 md:tracking-normal">
                    {item.attributes.Title}
                  </p>
                </Link>
                <div className="flex items-center gap-2">
                  <p className="body3 text-grey-800">
                    {date.getDate()}.{date.getMonth() + 1}.{date.getFullYear()}
                  </p>
                  <div className="mx-1 block size-1 rounded-full bg-gray-300" />
                  <p className="body3 hidden text-[13px] text-gray-500 md:block">
                    Founder and CEO
                  </p>
                  <p className="body3 block text-gray-500 md:hidden">
                    {readTime}&nbsp;{t('blog.min_read')}
                  </p>
                </div>
              </div>
              <Link
                href={`/blog/${item.attributes.slug}`}
                className="mb-3 ml-auto hidden size-8 rotate-180 md:block"
              >
                <Icon
                  name="shewronLeft"
                  className="pointer-events-none size-4 stroke-[2px] text-transparent md:size-8"
                />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
